import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'

Sentry.init({
  dsn:
    'https://45fe916995804ce2b6335f6e833751ed@o524538.ingest.sentry.io/5644583',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.01,
})

Sentry.setTag('client_side', 'true')
